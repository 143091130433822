import { defineStore, } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    hasCompany: false,
    isRegisterService: false,
    user: undefined as User | undefined,
  }),
  getters: ({ }),
  actions: {
    setUser(user: User){
      this.user = user;
    },
    setHasCompany(value: boolean){
      this.hasCompany = value;
    },
    setRegisterService(value: boolean){
      this.isRegisterService = value;
    },
    isLogin(){
      return !!this.token;
    },
    setToken(token: string) {
      this.token = token;
      localStorage.setItem("token", token);
    },
    logout(){
      this.user = undefined;
      this.token = "";
      this.hasCompany = false;
      this.isRegisterService = false;
      useRouter().replace("/");
    },
  },

  share: { enable: true },
  persist: true

});

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
// }

